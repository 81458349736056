<template>
    <div class="contact">
        <section class="px-4 py-12 md:py-16 sm:px-6 lg:px-8">
                <div class="max-w-screen-xl mx-auto">
                <div class="grid lg:grid-cols-2 lg:gap-x-8 xl:gap-x-16">
                    <div
                    class="flex flex-col items-start justify-between sm:items-center lg:items-start"
                    >
                    <div>
                        <div
                        class="w-full mt-4 text-left md:mt-5 sm:text-center lg:text-left"
                        >
                        <h1 data-aos="fade-up" data-aos-duration="500"
                            class="text-4xl text-center lg:text-start font-extrabold text-gray-800 dark:text-white sm:text-5xl md:text-6xl"
                        >
                            {{ $t("contact us") }}
                        </h1>
                        <p
                            data-aos="fade-up" data-aos-duration="800"
                            class="max-w-lg text-center sm:text-start mt-1 mx-auto text-xl text-gray-500 dark:text-gray-200 sm:max-w-2xl"
                        >
                            {{ $t('contact_desc') }}
                        </p>
                        </div>
                    </div>
                    <div
                        class="grid gap-6 mt-8  sm:grid-cols-2 sm:grid-rows-2 sm:gap-12"
                    >
                    <!-- Location 2 -->
                    <div data-aos="zoom-out" data-aos-duration="750">
                        <h5 class="text-lg font-bold text-gray-800 dark:text-gray-100">{{ $t('call_us') }}</h5>
                        <p class="mt-2 text-base text-gray-500 dark:text-gray-200" dir="ltr">
                            <a href="tel:+8681536279"  class="dark:text-white text-primary-100" >+86 81536279</a><br/>
                            <a href="tel:+8619866772048" class="dark:text-white text-primary-100" >+86 198 6677 2048</a><br/>
                            <a href="tel:+8618122497759" class="dark:text-white text-primary-100" >+86 181 2249 7759</a><br/>                            
                        </p>
                        </div>
                        <!-- Location 4 -->
                        <div data-aos="zoom-out" data-aos-duration="1250">
                        <h5 class="text-lg font-bold text-gray-800 dark:text-white">{{ $t('email_us') }}
                        </h5>
                        <p class="mt-1 text-base text-gray-500 dark:text-gray-200">
                                info@talialtd.com
                            <br/>
                        </p>
                        </div>
                        <div data-aos="zoom-out" data-aos-duration="500">
                        <h5 class="text-lg font-bold text-gray-800 dark:text-gray-100">
                            {{ $t('address_one') }}</h5>
                        <p class="mt-1 text-base text-gray-500 dark:text-gray-100">
                            {{ $t('address_one_line') }}
                        </p>
                        </div>

                        <div data-aos="zoom-out" data-aos-duration="500">
                        <h5 class="text-lg font-bold text-gray-800 dark:text-gray-100">
                            {{ $t('address_two') }}</h5>
                        <p class="mt-1 text-base text-gray-500 dark:text-gray-100">
                            {{ $t('address_two_line') }}
                        </p>
                        </div>



                        <!-- Location 3 -->
                        <div data-aos="zoom-out" data-aos-duration="1000">
                        <h5 class="text-lg font-bold text-gray-800 dark:text-white">{{ $t("working_hours") }}</h5>
                        <p class="mt-1 text-base text-gray-500 dark:text-gray-200">
                            {{ $t('working_days') }}
                            <br />{{ $t('working_time') }}
                        </p>
                        </div>

                        <div class="flex justify-center items-center justify-items-center">
                            <div class="w-full">
                                <h6 class="text-lg font-semibold text-center text-gray-600 dark:text-gray-100 " >
                            {{ $t('Follow Us') }}
                        </h6>
                        <div data-aos="zoom-in" dir="ltr"
                        class="flex mt-3 space-x-4 justify-center" >
                        <a class="flex items-center justify-center w-12 h-12 transition duration-300 ease-in-out rounded-full
                        bg-gradient-to-r from-[#833ab4] via-[#fd1d1d] to-[#fcb045] text-white"
                            href="https://www.instagram.com/talialtd.ar/" >
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6"
                            viewBox="2 2 20 20"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round" >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <rect x="4" y="4" width="16" height="16" rx="4" />
                            <circle cx="12" cy="12" r="3" />
                            <line x1="16.5" y1="7.5" x2="16.5" y2="7.501" />
                            </svg>
                        </a>
                        <a class="flex items-center justify-center w-12 h-12 
                        transition duration-300 ease-in-out rounded-full 
                        bg-[#4267B2]
                        text-white"
                            href="https://www.facebook.com/talialtd.ar">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6 text-white"
                            viewBox="2 2 20 20"
                            stroke-width="1.5"
                            stroke="currentColor"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            >
                            <path stroke="none" d="M0 0h24v24H0z" fill="none" />
                            <path
                                d="M7 10v4h3v7h4v-7h3l1 -4h-4v-2a1 1 0 0 1 1 -1h3v-4h-3a5 5 0 0 0 -5 5v2h-3"
                            />
                            </svg>
                        </a>
                        <a class="flex items-center justify-center w-12 h-12 transition duration-300 
                        ease-in-out rounded-full 
                        bg-black
                        text-white" href="https://www.x.com/talialtd">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6 text-white"
                            viewBox="0 0 16 16"
                            stroke-width="0.2"
                            stroke="currentColor"
                            fill="#fff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            >
                            <path d="M12.6.75h2.454l-5.36 6.142L16 15.25h-4.937l-3.867-5.07-4.425 5.07H.316l5.733-6.57L0 .75h5.063l3.495 4.633L12.601.75Zm-.86 13.028h1.36L4.323 2.145H2.865z"/>
                            </svg>
                        </a>
                        <a class="flex items-center justify-center w-12 h-12 transition duration-300 
                        ease-in-out rounded-full 
                        bg-[#0077B5]
                        text-white"
                            href="https://www.linkedin.com/company/talia-trading-logistics/">
                            <svg
                            xmlns="http://www.w3.org/2000/svg"
                            class="w-6 h-6 text-white"
                            viewBox="0 0 16 16"
                            stroke-width="0.2"
                            stroke="currentColor"
                            fill="#fff"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                            >
                            <path d="M0 1.146C0 .513.526 0 1.175 0h13.65C15.474 0 16 .513 16 1.146v13.708c0 .633-.526 1.146-1.175 1.146H1.175C.526 16 0 15.487 0 14.854zm4.943 12.248V6.169H2.542v7.225zm-1.2-8.212c.837 0 1.358-.554 1.358-1.248-.015-.709-.52-1.248-1.342-1.248S2.4 3.226 2.4 3.934c0 .694.521 1.248 1.327 1.248zm4.908 8.212V9.359c0-.216.016-.432.08-.586.173-.431.568-.878 1.232-.878.869 0 1.216.662 1.216 1.634v3.865h2.401V9.25c0-2.22-1.184-3.252-2.764-3.252-1.274 0-1.845.7-2.165 1.193v.025h-.016l.016-.025V6.169h-2.4c.03.678 0 7.225 0 7.225z" />
                            </svg>
                        </a>
                        </div>
                            </div>

                    </div>
                    </div>

                    </div>
                    <div class="w-full mt-12 lg:mt-0">
                        <h1 data-aos="fade-up" data-aos-duration="500"
                            class="text-3xl text-center font-extrabold text-gray-800 dark:text-white"
                        >
                            {{ $t("our location") }}
                        </h1>
                        <iframe
                        class="w-full mt-12 rounded-2xl object-cover h-96"
                        src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d14673.85546459769!2d113.22052686327443!3d23.153264235545!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x3402f84cb8270dcf%3A0x8f07758b1adcd64b!2sSong%20Nan%20Lu%2C%20Bai%20Yun%20Qu%2C%20Guang%20Zhou%20Shi%2C%20Guang%20Dong%20Sheng%2C%20China%2C%20510165!5e0!3m2!1sen!2s!4v1711730646926!5m2!1sen!2s" 
                        loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>
                    </div>
                </div>
                </div>
            </section>
            
    </div>
</template>
